import React from "react"
import { REACT_APP_SIGNUP_URL } from "../../constants"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"

function redirect() {
  var url = ""
  window.location(url)
}

export function CTAAffordable() {
  return (
    <ScrollAnimation duration={2} animateIn="fadeIn">
      <section className="text-blue-night font-body mt-4 md:mt-0">
        <div className="container lg:pb-24 mx-auto">
          <div className="lg:w-10/12 md:mx-auto mx-4 bg-cta-pattern bg-cover bg-no-repeat bg-center sm:p-12 p-4 rounded-medium text-center">
            <h1 className="flex-grow sm:pb-8 pb-4 xl:text-xl text-lg font-medium text-white">
              Nền tảng tiên phong hỗ trợ toàn diện hoạt động marketing của sales
              Bất động sản với chi phí hợp lý nhất.
            </h1>
            <div className="flex items-center justify-center">
              <Link
                id="cta_affordable"
                to={`${REACT_APP_SIGNUP_URL}`}
                target="_blank"
                className="bg-white border-0 py-2 px-8 focus:outline-none hover:text-white rounded-md text-md"
              >
                <span className="text-gradient bg-gradient-to-tr from-blue font-bold to-blue-light2">
                  Dùng thử miễn phí
                </span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  )
}

export function CTA2(props) {
  return (
    <section className="text-blue-night font-body">
      <div className="container mx-auto">
        <div className="lg:w-10/12 md:mx-auto mx-4 bg-cta-pattern bg-cover bg-no-repeat bg-center sm:p-12 p-4 rounded-medium text-center">
          <h1 className="flex-grow sm:pb-4 pb-2 xl:text-xl text-lg font-medium text-white">
            {props.title}
          </h1>
          <p className="text-white sm:pb-4 pb-2">
            Tạo tài khoản và trải nghiệm.
          </p>
          <div className="flex items-center justify-center">
            <Link
              id="cta_affordable"
              to={`${REACT_APP_SIGNUP_URL}`}
              target="_blank"
              className="bg-white border-0 py-2 px-8 focus:outline-none hover:text-white rounded-md text-md"
            >
              <span className="text-gradient bg-gradient-to-tr from-blue font-bold to-blue-light2">
                Dùng thử miễn phí
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
