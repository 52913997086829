import React from "react"
import { Link } from "gatsby"
import { REACT_APP_DASHBOARD_URL } from "../../constants"
import ScrollAnimation from "react-animate-on-scroll"
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css"
import "./Slider.css"
import { SectionTitle } from "../parts/Title"

export function Pricing() {
  const [valueNumberOfMember, setValueNumberOfMember] = React.useState(1)
  const formatValue = (value: number) => {
    if (value == 1) return `5 thành viên`
    if (value == 2) return `10 thành viên`
    if (value == 3) return `20 thành viên`
    if (value == 4) return `40 thành viên`
    return `1 thành viên`
  }
  const renderPricingStater = () => {
    if (valueNumberOfMember == 1) return `399.000₫`
    if (valueNumberOfMember == 2) return `720.000₫`
    if (valueNumberOfMember == 3) return `1.300.000₫`
    if (valueNumberOfMember == 4) return `2.350.000₫`
    return `399.000₫`
  }
  const renderPricingPro = () => {
    if (valueNumberOfMember == 1) return `799.000₫`
    if (valueNumberOfMember == 2) return `1.440.000₫`
    if (valueNumberOfMember == 3) return `2.600.000₫`
    if (valueNumberOfMember == 4) return `4.700.000₫`
    return `799.000₫`
  }
  const renderMaxLead = () => {
    if (valueNumberOfMember == 1) return `150`
    if (valueNumberOfMember == 2) return `300`
    if (valueNumberOfMember == 3) return `600`
    if (valueNumberOfMember == 4) return `1200`
    return `150`
  }
  return (
    <ScrollAnimation duration={2} animateIn="fadeIn">
      <section
        className="text-blue-night font-body overflow-hidden"
        id="pricing"
      >
        <div className="container px-5 py-4 pt-12 md:py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-8 md:mb-20">
            <div className="flex justify-center mb-1">
              <SectionTitle>Bảng giá</SectionTitle>
            </div>
            <p className="lg:w-1/2 mx-auto leading-relaxed text-base text-gray-normal">
              Nhận thêm nhiều khách, tiết kiệm ngân sách khi sử dụng các gói
              cước trả phí được thiết kế theo tăng trưởng kinh doanh của bạn.
            </p>
          </div>
          <div className="md:w-8/12 w-10/12 mx-auto relative">
            <div
              className={`absolute w-tiny h-2 ${
                valueNumberOfMember > 1 ? "bg-white z-10" : "bg-gray-black"
              } left-1/4 top-sm`}
            ></div>
            <div
              className={`absolute w-tiny h-2 ${
                valueNumberOfMember > 2 ? "bg-white z-10" : "bg-gray-black"
              } left-2/4 top-sm`}
            ></div>
            <div
              className={`absolute w-tiny h-2 ${
                valueNumberOfMember > 3 ? "bg-white z-10" : "bg-gray-black"
              } left-3/4 top-sm`}
            ></div>
            <InputRange
              formatLabel={(value) => formatValue(value)}
              step={1}
              maxValue={4}
              minValue={0}
              value={valueNumberOfMember}
              onChange={(value1) => {
                if (value1 === 0) return setValueNumberOfMember(1)
                setValueNumberOfMember(value1)
              }}
            />
          </div>
          <div className="flex lg:w-10/12 flex-wrap md:mt-16 mt-8 mx-auto">
            <div className="md:p-4 xl:w-1/3 mb-8 md:mb-0 md:w-1/3 w-full">
              <div className="h-full rounded-lg border-thin border-gray-light flex flex-col relative overflow-hidden">
                <h2 className="text-lg md:mt-11 mb-1 mt-4 px-4 font-bold">
                  Gói miễn phí trọn đời
                </h2>
                <h1 className="text-base text-gradient bg-gradient-to-tr from-blue to-blue-light2 font-medium px-4 pb-4 md:pb-8 border-b-thin border-gray-light leading-medium tracking-tighter2">
                  Dành cho người mới bắt đầu. Kiếm những khách hàng đầu tiên.
                </h1>
                <div className="p-4 flex flex-col h-full">
                  <div>
                    <h3 className="text-xl font-bold text-bold mb-2">
                      Miễn phí
                    </h3>
                    <p className="mb-8 text-sm md:block hidden invisible font-normal text-gray-normal">
                      Tất cả quyền lợi của gói Miễn phí, và:
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Tạo landing không giới hạn
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Xem thông tin tối đa 10 khách/ tháng
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Xuất bản landing page trên các tên miền miễn phí
                    </p>
                    <p className="flex items-center mb-6">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Kết nối tối đa 2 tài khoản quảng cáo/ người dùng
                    </p>
                  </div>
                  <Link
                    id="pricing_free"
                    to={`${REACT_APP_DASHBOARD_URL}`}
                    className="flex justify-center text-center font-bold mt-auto text-blue border-blue border py-md px-4 w-full focus:outline-none hover:bg-blue hover:text-white rounded-md"
                  >
                    Dùng thử
                  </Link>
                  <Link
                    to="/pricing"
                    className="text-blue mt-4 underline text-center"
                  >
                    Tìm hiểu thêm
                  </Link>
                </div>
              </div>
            </div>

            <div className="md:p-4 xl:w-1/3 mb-8 md:mb-0 md:w-1/3 w-full">
              <div className="h-full rounded-lg border-thin border-gray-light flex flex-col relative overflow-hidden">
                <span className="text-white bg-gradient-to-r from-blue to-blue-light2 font-bold px-3 py-1 tracking-widest text-center text-md absolute left-0 right-0 top-0">
                  PHỔ BIẾN NHẤT
                </span>
                <h2 className="text-lg mt-11 mb-1 px-4 font-bold">
                  Gói cơ bản
                </h2>
                <h1 className="text-base text-gradient bg-gradient-to-tr from-blue to-blue-light2 font-medium px-4 pb-4 md:pb-8 border-b-thin border-gray-light leading-medium tracking-tighter2">
                  Hỗ trợ đội nhóm. Tối ưu tỷ lệ & hiệu quả chuyển đổi, tăng số
                  lượng khách hàng.
                </h1>
                <div className="p-4 flex flex-col h-full">
                  <div>
                    <h3 className="text-xl font-bold text-bold mb-2 flex flex-wrap items-center">
                      {renderPricingStater()}
                      <span className="text-md ml-1 font-medium text-gray-light">
                        /tháng
                      </span>
                    </h3>
                    <p className="mb-8 text-sm font-normal text-gray-normal">
                      Tất cả quyền lợi của gói Miễn phí, và:
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Tạo và quản lý đội nhóm
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Xuất bản landing page trên tên miền tuỳ chọn
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Kho thư viện 100+ mẫu giao diện
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Tính năng chặn IP ảo
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Xem thông tin tối đa {renderMaxLead()} khách/ nhóm/ tháng
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Kết nối tối đa 5 tài khoản quảng cáo/ người dùng
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Tối ưu quảng cáo thông minh
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Chatbot tích hợp sẵn kịch bản
                    </p>
                    <p className="flex items-start mb-6">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Báo cáo phân tích chuyên sâu
                    </p>
                  </div>
                  <Link
                    id="pricing_starter"
                    to={`${REACT_APP_DASHBOARD_URL}`}
                    className="flex justify-center text-center font-bold mt-auto text-white bg-blue border-blue border py-md px-4 w-full focus:outline-none hover:bg-white hover:text-blue rounded-md"
                  >
                    Nâng cấp ngay
                  </Link>
                  <Link
                    to="/pricing"
                    className="text-blue mt-4 underline text-center"
                  >
                    Tìm hiểu thêm
                  </Link>
                </div>
              </div>
            </div>
            <div className="md:p-4 xl:w-1/3 mb-12 md:mb-0 md:w-1/3 w-full">
              <div className="h-full rounded-lg border-thin border-gray-light flex flex-col relative overflow-hidden">
                <span className="text-white bg-gradient-to-r from-blue to-blue-light2 font-bold px-3 py-1 tracking-widest text-center text-md absolute left-0 right-0 top-0">
                  ĐỀ XUẤT
                </span>
                <h2 className="text-lg mt-11 mb-1 px-4 font-bold">
                  Gói chuyên nghiệp
                </h2>
                <h1 className="text-base text-gradient bg-gradient-to-tr from-blue to-blue-light2 font-medium px-4 pb-4 md:pb-8 border-b-thin border-gray-light leading-medium tracking-tighter2">
                  Hỗ trợ đội nhóm. Bứt phá doanh số, khai thác nhiều giá trị từ
                  một khách hàng.
                </h1>
                <div className="p-4 flex flex-col h-full">
                  <div>
                    <h3 className="text-xl font-bold text-bold mb-2 flex flex-wrap items-center">
                      {renderPricingPro()}
                      <span className="text-md ml-1 font-medium text-gray-light">
                        /tháng
                      </span>
                    </h3>
                    <p className="mb-8 text-sm font-normal text-gray-normal">
                      Tất cả quyền lợi của gói Cơ bản, và:
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Xem thông tin và quản lý khách hàng không giới hạn
                    </p>
                    <p className="flex items-start mb-2">
                      <span className="w-4 h-4 mr-sm mt-1 inline-flex items-center justify-center border-blue border-thin text-white rounded-full flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="11"
                          viewBox="0 0 12 11"
                        >
                          {" "}
                          <g fill="none" fill-rule="evenodd">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <g>
                                    {" "}
                                    <g>
                                      {" "}
                                      <g>
                                        {" "}
                                        <g>
                                          {" "}
                                          <g>
                                            {" "}
                                            <g>
                                              {" "}
                                              <path
                                                fill="#FFF"
                                                d="M0 0H10.667V10.667H0z"
                                                opacity=".01"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                              <path
                                                fill="#4C7CF3"
                                                fill-rule="nonzero"
                                                d="M4.425 9.22c-.253 0-.443-.092-.615-.3L1.523 6.112c-.137-.16-.19-.31-.19-.47 0-.366.27-.631.646-.631.221 0 .38.084.526.265l1.902 2.397 3.723-5.89c.155-.248.314-.337.567-.337.371 0 .636.261.636.624 0 .137-.044.283-.146.442L5.048 8.898c-.146.216-.354.323-.623.323z"
                                                transform="translate(-903 -4896) translate(0 4210) translate(286 251) translate(0 112) translate(600) translate(15 265) translate(0 52) translate(0 3) translate(2.667 3)"
                                              />{" "}
                                            </g>{" "}
                                          </g>{" "}
                                        </g>{" "}
                                      </g>{" "}
                                    </g>{" "}
                                  </g>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>{" "}
                        </svg>
                      </span>
                      Kết nối tối đa 10 tài khoản quảng cáo/ người dùng
                    </p>
                  </div>
                  <Link
                    to={`${REACT_APP_DASHBOARD_URL}`}
                    id="pricing_pro"
                    className="flex justify-center text-center font-bold mt-auto text-white bg-blue border-blue border py-md px-4 w-full focus:outline-none hover:bg-white hover:text-blue rounded-md"
                  >
                    Nâng cấp ngay
                  </Link>
                  <Link
                    to="/pricing"
                    className="text-blue mt-4 underline text-center"
                  >
                    Tìm hiểu thêm
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  )
}
